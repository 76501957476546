import { Col, Row, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import AffiliateStep1 from './affiliate_step1.svg'
import AffiliateStep2 from './affiliate_step2.svg'
import AffiliateStep3 from './affiliate_step3.svg'
import AffiliateStep4 from './affiliate_step4.svg'

const AffiliateProgram = () => {
  const BannerStyle = {
    backgroundColor: '#f1f1f1',
    border: '2px solid #f1f1f1',
    borderRadius: '25px',
  }

  const hrStyle = {
    border: 'none',
    width: '200px',
    height: '2px',
    backgroundColor: '#333',
  }

  return (
    <Row align="middle" justify="center" style={BannerStyle} className="m3 py3">
      <Col md={20} xs={20} className="center my3">
        <Typography.Title level={2}>
          <FormattedMessage id="comp.affiliateprogram.title" />
        </Typography.Title>
        <hr style={hrStyle} />
      </Col>
      <Col md={20} xs={20} className="m2 center">
        <Typography.Text strong>
          <FormattedMessage id="comp.affiliateprogram.description" />
        </Typography.Text>
      </Col>
      <Col md={20} xs={20} className="center">
        <Row justify="center" align="middle" className="my2">
          <Col md={10} xs={20} className="m2">
            <img src={AffiliateStep1} alt="Affiliate Step 1" />
          </Col>
          <Col md={10} xs={20} className="m2">
            <img src={AffiliateStep2} alt="Affiliate Step 2" />
          </Col>
          <Col md={10} xs={20} className="m2">
            <img src={AffiliateStep3} alt="Affiliate Step 3" />
          </Col>
          <Col md={10} xs={20} className="m2">
            <img src={AffiliateStep4} alt="Affiliate Step 4" />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AffiliateProgram
