import {
  Row,
  Col,
  Typography,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import React from 'react'

import './style.css'
import healthBtn from './affiliate_health_button.svg'
import leisureBtn from './affiliate_leisure_button.svg'

const AffiliateIndustry = () => {
  const BannerStyle = {
    backgroundColor: '#ffcc33',
    border: '2px solid #ffcc33',
    borderRadius: '25px',
  }

  const hrStyle = {
    border: 'none',
    width: '200px',
    height: '2px',
    backgroundColor: '#333',
  }

  return (
    <Row align="middle" justify="center" style={BannerStyle} className="m3 py3">
      <Col md={20} xs={20} className="center my3">
        <Typography.Title level={3}>
          <FormattedMessage id="comp.affiliateindustry.title" />
        </Typography.Title>
        <hr style={hrStyle} />
      </Col>
      <Col md={20} xs={20} className="m2 center">
        <Typography.Title level={3}>
          <FormattedMessage id="comp.affiliateindustry.subtitle" />
        </Typography.Title>
      </Col>
      <Col md={20} xs={20} className="center my3">
        <Row justify="center" align="middle" className="my2">
          <Col md={10} xs={20} className="center">
            <Link to="/health" state={{ industry: 'health' }}>
              <img src={healthBtn} alt="Health Industry" />
            </Link>
          </Col>
          <Col md={10} xs={20} className="center">
            <Link to="/leisure" state={{ industry: 'leisure' }}>
              <img src={leisureBtn} alt="Leisure Industry" />
            </Link>
          </Col>
        </Row>
      </Col>
      <Col md={18} xs={20} className="center m3">
        <Typography.Title level={4}>
          <FormattedMessage id="comp.affiliateindustry.description" />
        </Typography.Title>
      </Col>
      <Col md={18} xs={24} className="center">
        <Row justify="center" align="middle" className="my4">
          <Col md={11} xs={22} className="m1">
            <div className="outer-div col1-outer-div">
              <div className="inner-div col1-inner-div">
                <Row className="mb2 left-align">
                  <Col md={24} xs={24}>
                    <Typography.Text strong>
                      <FormattedMessage id="comp.affiliatepromote.health.title" />
                    </Typography.Text>
                  </Col>
                </Row>
                <Row className="left-align">
                  <Col md={24} xs={24}>
                    <Typography.Paragraph>
                      <FormattedMessage id="comp.affiliatepromote.health.stats" />
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={10} xs={22} className="m2">
            <div className="outer-div col2-outer-div">
              <div className="inner-div col2-inner-div">
                <Row className="mb2 left-align">
                  <Col md={24} xs={24}>
                    <Typography.Text strong className="my2">
                      <FormattedMessage id="comp.affiliatepromote.leisure.title" />
                    </Typography.Text>
                  </Col>
                </Row>
                <Row className="left-align">
                  <Col md={24} xs={24}>
                    <Typography.Paragraph>
                      <FormattedMessage id="comp.affiliatepromote.leisure.stats" />
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AffiliateIndustry
