import {
  Row,
  Col,
  Collapse,
  Typography,
} from 'antd'
import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import AffiliateBanner from '../components/AffiliateBanner'
import AffiliateProgram from '../components/AffiliateProgram'
import AffiliatePromote from '../components/AffiliatePromote'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'

const AffiliatePage = () => {
  const contactFormRef = useRef(null)

  const style = {
    backgroundColor: 'white',
    marginTop: '64px',
  }

  const contactStyle = {
    backgroundColor: '#b7e7f9',
    paddingTop: 50,
  }
  return (
    <Layout selectedMenuKey="affiliate" customStyle={style}>
      <AffiliateBanner contactFormRef={contactFormRef} />

      <AffiliateProgram />

      <AffiliatePromote />

      <div style={contactStyle}>
        <Row justify="center">
          <Col xs={20} md={12} className="center py3">
            <Typography.Text strong className="h3">
              <FormattedMessage id="page.affiliate.tell-us" />
            </Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={20} md={12}>
            <ContactForm showSelect={false} formType="affiliate" contactFormRef={contactFormRef} />
          </Col>
        </Row>
      </div>
      <Row justify="center" type="flex" style={{ backgroundColor: '#f1f1f1' }}>
        <Col md={18} xs={20} className="my2">
          <Collapse bordered={false}>
            <Collapse.Panel
              header="How does the Raffskie Affiliate program work?"
              key="How does the Raffskie Affiliate program work?"
            >
              <p>
                You can share a Contest or Giveaway with your audience through customized linking tools and earn
                money on qualified campaigns.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="How do I qualify for this program?"
              key="How do I qualify for this program?"
            >
              <p>
                Approved bloggers, publishers, influencers and content creators with a qualified website or
                mobile app can participate in this program.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="How do I earn money in this program?"
              key="How do I earn money in this program?"
            >
              <p>
                You earn fees from your audience participating in Contests and Giveaways.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="What are the Raffskie Affiliate fees?"
              key="What are the Raffskie Affiliate fees?"
            >
              <p>
                Raffskie provides up to 10% in Affiliate fees for qualified campaigns.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="Are fees paid out if a Backer Campaign fails?"
              key="Are fees paid out if a Backer Campaign fails?"
            >
              <p>
                Unfortunately, No.
              </p>
              <p>
                Raffskie&apos;s Backer Campaigns are all or nothing. Raffskie only pays out a
                fee on a qualified Campaign. This means that a Campaign has to be either launched as Live or have
                transitioned to Live from a successful Backer Campaign.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="Do my Affiliate fees get cancelled if there is a refund?"
              key="Do my Affiliate fees get cancelled if there is a refund?"
            >
              <p>
                Yes. Raffskie automatically tracks for cancelled and refunded orders. Raffskie sets a review period
                before fees can be paid out to ensure fees are only paid on valid orders.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="How does the Raffskie Affiliate tracking work?"
              key="How much does it cost to be a Prize Sponsor?"
            >
              <p>
                When you promote or send traffic to a Raffskie Contest or Giveaway, you&apos;ll use an affiliate link.
                An affiliate link includes a unique ID that we call a Campaign ID.
              </p>
              <p>
                When someone clicks on your affiliate link, the Campaign ID gets stored in a browser cookie
                on that user&apos;’s computer or mobile device.
              </p>
              <p>
                The cookie contains several pieces of information to help us track their activity after they click
                your link. Once that person decides to enter a Contest or Giveaway, they&apos;re required to enter
                their email address which lets us register their participation in the Affiliate dashboard.
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header="What's a Backer Campaign?"
              key="What's a Backer Campaign?"
            >
              <p>
                Backer Campaigns are designed to create interest for a specific kind of Contest or Giveaway.
                They never go Live until the Campaign meets the minimum interest and goal.
              </p>
              <p>
                After which time every Backer automatically becomes an Entrant.
                A Backer Campaign on Raffskie is all-or-nothing.
              </p>
            </Collapse.Panel>

            <Collapse.Panel
              header="What's a Live Campaign?"
              key="What's a Live Campaign?"
            >
              <p>
                Live Campaigns are Contests or Giveaways that have either been launched as Live or have transitioned
                to Live from a successful Backer Campaign.
              </p>
            </Collapse.Panel>

            <Collapse.Panel
              header="What are Raffskie Contests?"
              key="What are Raffskie Contests?"
            >
              <p>
                Raffskie Contests are a type of promotion where entrants involve an element of skill, and winners are
                chosen based on the merit of their entry.
              </p>
              <p>
                Contests can generally incentivize purchases as part of the promotion. Think of a Contest
                as a game of skill.
              </p>
            </Collapse.Panel>

            <Collapse.Panel
              header="What are Raffskie Giveaways?"
              key="What are Raffskie Giveaways?"
            >
              <p>
                Raffskie Giveaways are a type of promotion where prizes are randomly given away to entrants who enter by
                completing designated actions that don&apos;t require a purchase or other forms of consideration.
                Think of a Giveaway as a game of luck.
              </p>
            </Collapse.Panel>

            <Collapse.Panel
              header="What is a Backer?"
              key="What is a Backer?"
            >
              <p>
                Raffskie Backers are people who pledge support for a specific kind of Contest or Giveaway in the
                hopes it will have created the necessary interest and support to transition to Live.
              </p>
            </Collapse.Panel>

            <Collapse.Panel
              header="What is an Entrant?"
              key="What is an Entrant?"
            >
              <p>
                Raffskie Entrants are people who agree to enter and participate in a Contest or Giveaway.
                This can happen two ways. Either a Contest or Giveaway has been launched as Live or has
                successfully transitioned to Live from a Backer Campaign.
              </p>
            </Collapse.Panel>

            <Collapse.Panel
              header="How do I sign up to the program?"
              key="How do I sign up to the program?"
            >
              <p>
                Fill in your contact details in the above form and someone from our Affiliate program will
                reach out to you.
              </p>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </Layout>
  )
}

export default AffiliatePage
