import {
  Row,
  Col,
  Typography,
} from 'antd'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import PropTypes from 'prop-types'

import SslLogo from '../../assets/img/ssl.svg'

import Banner from './affiliate_banner.svg'

const AffiliateBanner = ({ contactFormRef }) => {
  const BannerStyle = {
    backgroundColor: '#66ccff',
    border: '2px solid #66ccff',
    borderRadius: '25px',
  }

  const CTAStyle = {
    backgroundColor: 'white',
    border: '2px solid',
    borderRadius: '50px',
    boxShadow: '10px 10px #808080',
    cursor: 'pointer',
    color: 'black',
    fontFamily: 'PT Sans',
    fontWeight: 'bold',
    height: '80px',
    outline: 'none',
    width: '215px',
  }

  const hrStyle = {
    border: 'none',
    width: '200px',
    height: '2px',
    backgroundColor: '#333',
  }

  return (
    <Row align="middle" justify="center" style={BannerStyle} className="m3">
      <Col md={12} xs={20} className="m2 center">
        <Row justify="center">
          <Col md={20} xs={20} className="center pb3">
            <Typography.Title level={2}>
              <FormattedMessage id="comp.affiliatebanner.title" />
            </Typography.Title>
            <hr style={hrStyle} />
          </Col>
          <Col md={24} className="left-align">
            <ul>
              <li>
                <Typography.Title level={4}>
                  <FormattedMessage id="comp.affiliatebanner.subtitle1" />
                </Typography.Title>
              </li>
              <li>
                <Typography.Title level={4}>
                  <FormattedMessage id="comp.affiliatebanner.subtitle2" />
                </Typography.Title>
              </li>
            </ul>
          </Col>
        </Row>
        <Row justify="center">
          <Col md={24} className="center h3">
            <button
              style={CTAStyle}
              type="button"
              onClick={() => {
                const y = contactFormRef.current.getBoundingClientRect().y - 150 + window.pageYOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
              }}
            >
              <FormattedMessage id="comp.affiliatebanner.cta-button.name" />
            </button>
          </Col>
        </Row>
      </Col>
      <Col md={10} xs={20} className="center">
        <Row justify="center" align="middle">
          <Col md={24} xs={24} className="my4">
            <img src={Banner} alt="Sponsor Banner" style={{ width: '80%' }} />
          </Col>
        </Row>
        <Row justify="center" align="middle" className="my2">
          <Col>
            <img src={SslLogo} alt="SSL Secure" style={{ height: 50 }} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

AffiliateBanner.propTypes = {
  contactFormRef: PropTypes.object.isRequired,
}

export default AffiliateBanner
